import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import AboutUs from './about'

const Index = () => {
    return (
        <div>
            <AboutUs />
        </div>
    )
}

export default Index
