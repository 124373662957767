import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { CircularProgress, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDeleteModel(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDeleteModel.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  // eslint-disable-next-line react/prop-types
  const { opendelete, handleClosedelete, deletedata, id, isLoading } = props;

  const Delete = () => {
    deletedata(id)
  }

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={opendelete}
      >
        <BootstrapDeleteModel
          id="customized-dialog-title"
          onClose={handleClosedelete}
        >
          Delete
        </BootstrapDeleteModel>
        <DialogContent dividers>
          <Typography gutterBottom p={3}>
            Are you sure you want to delete this item?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <LoadingButton onClick={Delete} variant='contained' color="error" disabled={!!isLoading} >
              {isLoading ? <CircularProgress size={27} /> : 'Yes'}
            </LoadingButton>
            <Button variant="contained" onClick={handleClosedelete}>No</Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
